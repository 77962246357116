<template>
  <div id="WeChatApplet">
    <top :search_transmit_data='{data,type:"微信南航选座"}'></top>
    <!-- 主体表格 -->
    <div class="WeChat_body_div">
      <!-- 导出数据 -->
      <div class="vue-json-excel" v-if="Terminal_type == 'pc'">
        <template>
          <download-excel class="export-excel-wrapper" :data="DetailsForm" :fields="json_fields" header="微信小程序选座数据报表"
            name="微信小程序选座数据报表.xls">
            <el-button type="success">数据导出</el-button>
          </download-excel>
        </template>
      </div>

      <div class="WeChat_body" :style="'height:' + table_height + 'px'">
        <table class="WeChat_table" border="1" style="table-layout: fixed">
          <tr>
            <td>姓名</td>
            <td>票号</td>
            <td>座位</td>
            <td v-if="Terminal_type == 'pc'">航段下标</td>
            <td v-if="Terminal_type == 'pc'">南航里程</td>
            <td v-if="Terminal_type == 'pc'">南航账号</td>
            <td v-if="Terminal_type == 'pc'">南航密码</td>
            <td v-if="Terminal_type == 'pc'">支付金额</td>
            <td v-if="Terminal_type == 'pc'">创建时间</td>
            <td v-if="Terminal_type == 'pc'">下单人员</td>
            <td v-if="Terminal_type == 'pc'">订单状态</td>
          </tr>
          <tr v-for="(item, index) in data" :key="index" @click="mobile(item, index)">
            <!-- 姓名 -->
            <td>
              <span v-for="(it, id) in item.obj" :key="id">{{ it.name }},</span>
            </td>
            <!-- 票号 -->
            <td>{{ item.obj[0].kepiao }}</td>
            <!-- 座位 -->
            <td>
              <span v-for="(it, ind) in item.obj" :key="ind">{{ it.zuo }},</span>
            </td>
            <!-- 航段下标 -->
            <td v-if="Terminal_type == 'pc'">{{ item.hd_index }}</td>
            <!-- 南航里程 -->
            <td v-if="Terminal_type == 'pc'">
              <span v-for="(it, ind) in item.obj" :key="ind">{{ it.licheng }},</span>
            </td>
            <!--  -->
            <!-- 南航账号 -->
            <td v-if="Terminal_type == 'pc'">{{ item.admin }}</td>
            <!-- 南航密码 -->
            <td v-if="Terminal_type == 'pc'">{{ item.password }}</td>
            <!-- 支付金额 -->
            <td v-if="Terminal_type == 'pc'">{{ item.jine }}</td>
            <!-- 订单时间 -->
            <td v-if="Terminal_type == 'pc'">{{ item.created_time }}</td>
            <!-- 下单人员 -->
            <td v-if="Terminal_type == 'pc'">{{ item.personnel }}</td>
            <!-- 订单状态 -->
            <td :style="
                item.show_type == '待支付'
                  ? 'color:rgb(192 192 192);'
                  : 'color:green;'
              " v-if="Terminal_type == 'pc'">
              {{ item.show_type }}
            </td>
          </tr>
        </table>
      </div>
      <!-- mobile 独有-->
      <van-popup v-if="Terminal_type == 'mobile'" v-model="show" round position="bottom">
        <div class="WeChat_popup_div">
          <div class="WeChat_popup_flex">
            <p>姓名：</p>
            <p>
              <span v-for="(it, id) in popup_data.obj" :key="id">{{ it.name }},</span>
            </p>
          </div>
          <div class="WeChat_popup_flex">
            <p>票号：</p>
            <p v-show="data[data_index]">
              {{ data[data_index].obj[0].kepiao }}
            </p>
          </div>
          <div class="WeChat_popup_flex">
            <p>座位：</p>
            <p>
              <span v-for="(it, ind) in popup_data.obj" :key="ind">{{ it.zuo }},</span>
            </p>
          </div>
          <div class="WeChat_popup_flex">
            <p>南航里程</p>
            <p>
              <span v-for="(it, ind) in popup_data.obj" :key="ind">{{ it.licheng }},</span>
            </p>
          </div>
          <div class="WeChat_popup_flex">
            <p>南航账号：</p>
            <p>{{ popup_data.admin }}</p>
          </div>
          <div class="WeChat_popup_flex">
            <p>南航密码：</p>
            <p>{{ popup_data.password }}</p>
          </div>
          <div class="WeChat_popup_flex">
            <p>支付金额：</p>
            <p>{{ popup_data.jine }}</p>
          </div>
          <div class="WeChat_popup_flex">
            <p>订单时间：</p>
            <p>{{ popup_data.created_time }}</p>
          </div>
          <div class="WeChat_popup_flex">
            <p>订单状态：</p>
            <p :style="
                popup_data.show_type == '待支付'
                  ? 'color:rgb(192 192 192);'
                  : 'color:green;'
              ">
              {{ popup_data.show_type }}
            </p>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
  import top from "../components/BodyComponents.vue";
  export default {
    name: "WeChatApplet",
    data() {
      return {
        table_height: sessionStorage.getItem("table_height"),
        data: [],
        json_fields: {
          姓名: "name",
          第一个姓名票号: "piao",
          座位: "zuo",
          南航账号: "admin",
          南航密码: 'password',
          南航里程: 'licheng',
          支付金额: "money",
          创建时间: "time",
          订单状态: "type",
        },
        DetailsForm: [],
        show: false,
        popup_data: [],
        data_index: 0,
      };
    },
    components: {
      top,
    },
    created() {
      // 数据请求
      this.$https("selection/ren/jihe", "", "get").then((res) => {
        console.log(res);
        let json_data = res.data.data.order;
        this.data = json_data;
        // 配置导出excl表格数据
        for (let i in json_data) {
          this.DetailsForm[i] = {
            name: "",
            piao: "",
            zuo: "",
            admin: json_data[i].admin,
            password: json_data[i].password,
            money: json_data[i].jine,
            time: json_data[i].created_time,
            type: json_data[i].show_type,
          };
          for (let b in json_data[i].obj) {
            this.DetailsForm[i].name += `${json_data[i].obj[b].name},`;
            this.DetailsForm[i].piao += `${json_data[i].obj[b].kepiao},`;
            this.DetailsForm[i].zuo += `${json_data[i].obj[b].zuo},`;
            if (json_data[i].obj[b].licheng) {
              this.DetailsForm[i].licheng +=
                `${json_data[i].obj[b].licheng},`;
            }

          }
        }
      });
    },
    mounted() {
      console.log("进入mounted");
    },
    methods: {
      // mobile 独有
      mobile(tr_item, tr_index) {
        if (this.Terminal_type == "pc") return false;
        this.show = true;
        this.popup_data = tr_item;
        this.data_index = tr_index;
        console.log(tr_item);
      },
    },
  };

</script>

<style scoped>
  body {
    background: #fafafa;
  }

  /* 穿透element-ui 消息提示 */

</style>
